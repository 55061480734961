import React from 'react';
import PropTypes from 'prop-types';

import './index.scss';
import { useTranslation } from 'react-i18next';
import pdfIcon from '../../../images/pdf-file-icon.png';
import downloadIcon from '../../../images/download-icon.svg';

const DownloadFile = ({ title }) => {
  const { t } = useTranslation();
  const downloadText = t('downloadText', { returnObjects: true });
  return (
    <div className="download-file">
      <div className="flex-center">
        <img src={pdfIcon} alt="" className="mb-0 pdf-icon" />
        <p>{title ?? 'Cahier des charges emiketic'}</p>
      </div>

      <button type="button" className="flex-center">
        <span>{downloadText}</span>
        <img src={downloadIcon} alt="" className="mb-0" />
      </button>
    </div>
  );
};

DownloadFile.propTypes = {
  title: PropTypes.string,
};

DownloadFile.defaultProps = {
  title: '',
};
export default DownloadFile;
