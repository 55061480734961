/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components

// Local UI components
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-grid-system';
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';

// Style
import '../page-styles/sarl-folder-resume.scss';
import icon from '../images/createCompany/final-step.svg';
import DownloadFile from '../shared/UIKit/DownloadFile';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function SarlFolderResume() {
  /* ********************* HOOKS ********************************* */

  const { t } = useTranslation();
  const { successText, description, fileNames } = t('resume', {
    returnObjects: true,
  });
  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title="" />
      <Container>
        <div className="sarl-folder-resume">
          <Row gutterWidth={80}>
            <Col lg={6}>
              <div className="flex-center">
                <img src={icon} alt="" className="mb-30 mb-md-20" />
              </div>
              <p className="title mb-50 mb-md-30">{successText}</p>
              {description?.map((v) => (
                <p className="description mb-30 mb-md-20">{v}</p>
              ))}
            </Col>
            <Col lg={6} className="download-files">
              <div className="">
                {fileNames.map((title) => {
                  return <DownloadFile title={title} />;
                })}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["SarlFolderResume", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default SarlFolderResume;
